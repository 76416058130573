<template>
  <div>
    <topic-component :topic="$t('SideBarItems.questions.text')"></topic-component>
    <b-row>
      <b-col lg="6" sm="12" md="12">
        <b-card class="mb-4 ">
          <h4 class="mb-4">{{ $t('questions.link') }}</h4>
          <div class="box-url">
            <a href="https://www.surveymonkey.com/r/SMS2PRO" target="_blank">https://www.surveymonkey.com/r/SMS2PRO</a>
          </div>
        </b-card>
      </b-col>
    </b-row>

  </div>
</template>

<script>
import TopicComponent from "@/components/common/topicComponent";

export default {
  name: "questions",
  components: {TopicComponent},
  data() {
    return {}
  }
}
</script>

<style scoped>
.box-url {
  background: #F6F7FB;
  border-radius: 5px;
  padding: 15px
}
</style>